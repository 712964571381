import { createContext, lazy, useReducer } from 'react';
import type { routeParams } from './createRoutes';
import type {
	RenderConfigProps,
	RouteConfigReducerProps,
	RouteConfigResDataItemProps,
	RouteConfigStateProps
} from '../models/types/routeConfig';
// import createRoutes from './createRoutes';
import { getDataWithToken, to } from '../utils/request';
import { idMap } from 'utils/publicData'
import publicRoutes from "./public"
import FlatTalentDetail from 'pages/FlatTalentDetail';


const CftsApp = lazy(() => import('pages/index'));

const ResumeLiBrary = lazy(() => import('pages/Resume/Library'));
const OtherResumes = lazy(() => import('pages/Resume/OtherResume'));
const Setting = lazy(() => import('pages/Setting'));
// const SpecialTalents = lazy(() => import('pages/Resume/SpecialTalents'));
const ResumeLibraryDetail = lazy(() => import('pages/ResumeDetail/Library'));
const OtherResumeDetail = lazy(() => import('pages/ResumeDetail/Other'));

// 子属单位
const SubunitIndex = lazy(() => import('pages/Subunit/index'));
const SubunitAll = lazy(() => import('pages/Subunit/All'));
const SubunitDetail = lazy(() => import('pages/Subunit/Detail'));
const SubunitCreate = lazy(() => import('pages/Subunit/Create'));
const SubunitDetailActivity = lazy(() => import('pages/Subunit/ActivityDetail/index'));
const SubunitActiveCompanyDetail = lazy(() => import('pages/Subunit/ActivityCompanyDetail/Index'));
const SubunitActiveJobDetail = lazy(() => import('pages/Subunit/ActivityJobDetail/Index'));
const SubunitActiveResumeDetail = lazy(() => import('pages/Subunit/ActivityResumeDetail/Index'));

//企业
const CompanyIndex = lazy(() => import('pages/Company/Index'));
const CompanyALl = lazy(() => import('pages/Company/CompanyList/Index'));
const CompanyDetail = lazy(() => import('pages/Company/CompanyDetail/Index'));
const CompanyJob = lazy(() => import('pages/Company/CompanyJobList/Index'));
const CompanyJobDetail = lazy(() => import('pages/Company/JobDetail/Index'));
const CompanyResumeDetail = lazy(() => import('pages/Company/ResumeDetail/index'));
const CreateCompany = lazy(() => import('pages/Company/CreateCompany/Index'));

const Overview = lazy(() => import('pages/Overview'));
const StatsForm = lazy(() => import('pages/StatsForm'));
const CreateStatsForm = lazy(() => import('pages/CreateStatsForm'));

// 活动相关路由
const ActivityIndex = lazy(() => import('pages/Activity/index'));
const AllActivities = lazy(() => import('pages/Activity/All'));
const CreateActivity = lazy(() => import('pages/Activity/Create'));
const ActivityDetail = lazy(() => import('pages/Activity/Detail'));
const StatsFormDetail = lazy(() => import('pages/StatsFormDetail'));
const EditorActivity = lazy(() => import('pages/Activity/Editor'));
const ProcessActivity = lazy(() => import('pages/Activity/Process'));
const ActiveCompanyDetail = lazy(() => import('pages/Activity/ActivityCompanyDetail/Index'));
const ActiveJobDetail = lazy(() => import('pages/Activity/ActivityJobDetail/Index'));
const ActiveResumeDetail = lazy(() => import('pages/Activity/ActivityResumeDetail/Index'));

const NewsIndex = lazy(() => import('pages/News'));
const Advertise = lazy(() => import('pages/News/Advertise'));
const CreateAdvertise = lazy(() => import('pages/News/CreateAdvertise'));
const CompanyBulletin = lazy(() => import('pages/News/Bulletin'));
const CreateBulletin = lazy(() => import('pages/News/CreateBulletin'));

//文章
const InformationList = lazy(() => import('pages/Information/InformationList'))
const InformationIndex = lazy(() => import('pages/Information'))
const CreateInformation = lazy(() => import('pages/Information/CreateInformation'))
const MiniProgramInformationList = lazy(() => import('pages/MiniProgramInformation/InformationList'))
// const MiniProgramInformationIndex = lazy(() => import('pages/MiniProgramInformation'))
const MiniProgramCreateInformation = lazy(() => import('pages/MiniProgramInformation/CreateInformation'))

//企业用户
const CompanyUser = lazy(() => import('pages/CompanyUser'))
const CompanyUserRenewal = lazy(() => import('pages/CompanyUser/Renewal'))
const CompanyUserList = lazy(() => import('pages/CompanyUser/List'))
const CompanyUserRenewalMember = lazy(() => import('pages/CompanyUser/Renewal/RenewalMember'))

//操作记录
const Logs = lazy(() => import('pages/Logs'))
//意见反馈
const FeedBack = lazy(() => import('pages/FeedBack'))
// 员工合同
const Contract = lazy(() => import('pages/Contract'))
const OldContract = lazy(() => import('pages/OldContract'))
const AccountantContract = lazy(() => import('pages/AccountantContract'))
const CashierContract = lazy(() => import('pages/CashierContract'))
const ContractDetail = lazy(() => import('pages/ContractDetail'))
const OldContractRecord = lazy(() => import('pages/OldContractRecord'))
//合同管理
const ContractManage = lazy(() => import('pages/ContractManage'))


//阿姨管理
// const AuntIndex = lazy(() => import('pages/Aunt/index'));
const AuntManage = lazy(() => import('pages/Aunt/AuntManage'));
const AuntDetail = lazy(() => import('pages/Aunt/AuntDetail'))
// 订单管理
const OrderManage = lazy(() => import('pages/Aunt/OrderManage'));
const OrderDetail = lazy(() => import('pages/Aunt/OrderDetail'));
//政策管理
const PolicyManage = lazy(() => import("pages/Aunt/PolicyManage"));
const PolicyCreate = lazy(() => import("pages/Aunt/PolicyCreate"));
const TrainingManage = lazy(() => import("pages/Aunt/TrainingManage"));
const TrainingCreate = lazy(() => import("pages/Aunt/TrainingCreate"));
//人才公寓
const Flats = lazy(() => import("pages/Flats"));

// 考试
// const Exam = lazy(() => import("pages/ExamList/index"));
const ExamJobs = lazy(() => import("pages/ExamList/ExamJobList"));
const ExamApplied = lazy(() => import("pages/ExamList/ExamAppledList"));
const ExamCompany = lazy(() => import("pages/ExamList/ExamCompanyList"));
const SensitiveWords = lazy(() => import("pages/SensitiveWords"))


const route_404: routeParams = {
	path: '*',
	component: lazy(() => import('../pages/404')),
};

/**
 * createRoute的config
 */
const cftsRoutes: routeParams = {
	path: '/platform/:valley_id/',
	component: CftsApp,
	children: [
		{
			path: 'company',
			component: CompanyIndex,
			children: [
				{
					path: 'all',
					component: CompanyALl,
				},
				{
					path: 'all/:companyId',
					component: CompanyDetail,
					children: [
						{
							path: 'resume/:resumeId',
							component: CompanyResumeDetail,
						},
						{
							path: 'job/:jobId',
							component: CompanyJobDetail,
						},
					],
				},
				{
					path: 'job/',
					component: CompanyJob,
					children: [
						{
							path: ':jobId',
							component: CompanyJobDetail,
						},
					],
				},
				{
					path: "create",
					component: CreateCompany
				}
			],
		},
		{
			path: 'activity/',
			component: ActivityIndex,
			children: [
				{
					path: 'all',
					component: AllActivities,
				},
				{
					path: 'all/:activityId',
					component: ActivityDetail,
					children: [
						{
							path: 'job/:jobId',
							component: ActiveJobDetail,
						},
						{
							path: 'resume/:resumeId',
							component: ActiveResumeDetail,
						},
					],
				},
				{
					path: 'all/:activityId/company/:companyId',
					component: ActiveCompanyDetail,
					children: [
						{
							path: 'job/:jobId',
							component: ActiveJobDetail,
						},
						{
							path: 'resume/:resumeId',
							component: ActiveResumeDetail,
						},
					],
				},
				{
					path: 'create',
					component: CreateActivity,
				},
				{
					path: 'editor/:activityId',
					component: EditorActivity,
				},
				{
					path: 'process',
					component: ProcessActivity,
				},
			],
		},
		{
			path: 'resume/library/',
			component: ResumeLiBrary,
			children: [
				{
					path: ':resumeId/',
					component: ResumeLibraryDetail,
				},
			],
		},
		{
			path: 'resume/other/',
			component: OtherResumes,
			children: [
				{
					path: ':resumeId/',
					component: OtherResumeDetail,
				},
			],
		},
		{
			path: 'setting/',
			component: Setting,
		},
		{
			path: 'subunit/',
			component: SubunitIndex,
			children: [
				{
					path: '',
					component: SubunitAll,
				},
				{
					path: ':subunitId',
					component: SubunitDetail,
					children: [
						{
							path: 'resume/:resumeId/',
							component: ResumeLibraryDetail,
						},
					],
				},
				{
					path: ':subunitId/activity/:activityId',
					component: SubunitDetailActivity,
					children: [
						{
							path: "job/:jobId/",
							component: ActiveJobDetail
						}
					]
				},
				{
					path: ':subunitId/activity/:activityId/company/:companyId',
					component: SubunitActiveCompanyDetail,
					children: [
						{
							path: 'job/:jobId',
							component: SubunitActiveJobDetail,
						},
						{
							path: 'resume/:resumeId',
							component: SubunitActiveResumeDetail,
						},
					],
				},
				{
					path: 'create',
					component: SubunitCreate,
				},
			],
		},
		{
			path: 'overview/',
			component: Overview,
		},
		{
			path: 'stats_form/',
			component: StatsForm,
		},
		{
			path: 'stats_form/create',
			component: CreateStatsForm,
		},
		{
			path: 'stats_form/:id',
			component: StatsFormDetail,
		},
		{
			path: 'news',
			component: NewsIndex,
			children: [
				{
					path: 'advertise',
					component: Advertise,
				},
				{
					path: 'advertise/create',
					component: CreateAdvertise,
				},
				{
					path: 'bulletin',
					component: CompanyBulletin,
				},
				{
					path: 'bulletin/create',
					component: CreateBulletin,
				},
			],
		},
		{
			path: 'information/portal',
			component: InformationIndex,
			children: [
				{
					path: '',
					component: InformationList,
				},
				{
					path: 'create',
					component: CreateInformation,
				},
				{
					path: ':id',
					component: CreateInformation,
				},
			]
		},
		{
			path: 'information/miniprogram',
			component: InformationIndex,
			children: [
				{
					path: '',
					component: MiniProgramInformationList,
				},
				{
					path: 'create',
					component: MiniProgramCreateInformation,
				},
				{
					path: ':id',
					component: MiniProgramCreateInformation,
				},
			]
		},
		{
			path: "company_user",
			component: CompanyUser,
			children: [
				{
					path: "renewal",
					component: CompanyUserRenewal,
				},
				{
					path: "renewal/member",
					component: CompanyUserRenewalMember
				},
				{
					path: "list",
					component: CompanyUserList
				},
			]
		},
		{
			path: "feed_back",
			component: FeedBack
		},
		{
			path: "logs",
			component: Logs
		},
		{
			path: "contract",
			component: Contract
		},
		{
			path: "old_contract",
			component: OldContract
		},
		{
			path: "contract_manege",
			component: ContractManage
		},
		{
			path: "old_contract/record",
			component: OldContractRecord
		},
		{
			path: "accountant_contract",
			component: AccountantContract
		},
		{
			path: "cashier_contract",
			component: CashierContract
		},
		{
			path: "contract/:id",
			component: ContractDetail
		},
		{
			path: "accountant_contract/:id",
			component: ContractDetail
		},
		{
			path: "cashier_contract/:id",
			component: ContractDetail
		},
		{path: "manage", component: AuntManage},
		{path: "manage/:id", component: AuntDetail},
		{path: "order", component: OrderManage},
		{path: "order/:id", component: OrderDetail},
		{path: "policy", component: PolicyManage},
		{path: "policy/create", component: PolicyCreate},
		{path: "training", component: TrainingManage},
		{path: "training/create", component: TrainingCreate},
		{path: "flats", component: Flats},
		{path: "flats/:id", component: FlatTalentDetail},
		{path: "sensitive_words", component: SensitiveWords},
		{ path: "exam/job", component: ExamJobs },
		{ path: "exam/applied", component: ExamApplied },
		{ path: "exam/company", component: ExamCompany },
		route_404
	],
};

// 用于防止登录后等待路由权限时 因路由为空跳出当前路由目录进入 404
const defaultApp: routeParams = {
	path: '/platform/:valley_id/',
	component: CftsApp,
	children: [route_404]
}

export const RouteConfigContext = createContext<Partial<RouteConfigReducerProps>>({});

enum requestUrl {
	userAuthModels = `/users/user_permission/`,
}

export function useRouteConfig() {
	const initialState: RouteConfigStateProps = {
		loading: false,
		navBarConfig: [],
		routes: [...publicRoutes, defaultApp, route_404]
	};

	const [routeState, dispatch] = useReducer((state: RouteConfigStateProps, action: any) => {
		switch (action.type) {
			case 'SET_NAVBAR_CONFIG': {
				return { ...state, navBarConfig: action.data };
			}
			case 'SET_PROCESS_ROUTES': {
				return { ...state, routes: action.data };
			}
			case 'GET_ROUTE_CONFIG_START': {
				return { ...state, loading: true };
			}
			case 'GET_ROUTE_CONFIG_END': {
				return { ...state, loading: false };
			}
			default:
				return state;
		}
	}, initialState);

	async function getRouteConfig(user_valley_id: number) {
		let [error, res] = await to(getDataWithToken(requestUrl.userAuthModels));
		// let [error, res] = [null,{data:[]}];
		if (!error) {
			const authMap: Record<string, boolean> = {}
			res.data.forEach((c: RouteConfigResDataItemProps) => {
				authMap[c.table_name] = true
			})

			// 路由节点以及侧边栏渲染项的配置（此配置项的索引为侧边栏从上到下的顺序）
			const config: RenderConfigProps[] = [
				{
					isRender: authMap.overview,
					tableName: 'overview',
				},
				{
					isRender: authMap.career_fair,
					tableName: 'activity',
					children: [
						{
							isRender: true,
							tableName: 'all',
						},
						{
							isRender: idMap.includes(user_valley_id),
							tableName: 'process',
						}
					],
				},
				{
					isRender: authMap.resume||authMap.outside_resume,
					tableName: 'resume',
					children: [
						{
							isRender: authMap.resume,
							tableName: 'library',
						},
						{
						  isRender: authMap.outside_resume,
						  tableName: 'other',
						},
					],
				},
				{
					isRender: [authMap.company, authMap.job].some(c => c),
					tableName: 'company',
					children: [
						{
							isRender: authMap.company,
							tableName: 'all'
						},
						{
							isRender: authMap.job,
							tableName: 'job'
						}
					],
				},
				// 会员
				{
					isRender: authMap.company_vip,
					tableName: 'company_user',
					children: [
						{
							isRender: true,
							tableName: 'list',
						},
						{
							isRender: true,
							tableName: 'renewal',
						},
					]
				},
				// 考试
				{
					isRender: authMap.examination,
					tableName: 'exam',
					children: [
						{
							isRender: true,
							tableName: 'job',
						},
						{
							isRender: true,
							tableName: 'applied',
						},
						{
							isRender: true,
							tableName: 'company',
						},
					]
				},
				// 文章
				{
					isRender: authMap.valley_message,
					tableName: 'information',
					children: [
						{
							isRender: true,
							tableName: 'portal',
						},
						{
							isRender: true,
							tableName: 'miniprogram'
						}
					]
				},
				// 广告和企业弹窗
				{
					isRender: [authMap.advertising_information, authMap.admin_notice_company].some(c => c),
					tableName: 'news',
					children: [
						{
							isRender: authMap.advertising_information,
							tableName: 'advertise',
						},
						{
							isRender: authMap.admin_notice_company,
							tableName: 'bulletin'
						}
					]
				},
				{
					isRender: authMap.dispatch_contract,
					tableName: 'old_contract'
				},
				{
					isRender: authMap.company_labor_contract,
					tableName: 'contract_manege'
				},
				{
					isRender: authMap.labor_dispatch,
					tableName: 'contract'
				},
				{
					isRender: authMap.labor_dispatch_accounting,
					tableName: 'accountant_contract'
				},
				{
					isRender: authMap.sensitive_words,
					tableName: 'sensitive_words'
				},
				{
					isRender: authMap.labor_dispatch_cashier,

					tableName: 'cashier_contract'
				},
				{
					isRender: authMap.child_valley,
					tableName: 'subunit',
				},
				{
					isRender: authMap.manage,
					tableName: "manage",
				},
				{
					isRender: authMap.order,
					tableName: "order",
				},
				{
					isRender: authMap.training,
					tableName: "training",
				},
				{
					isRender: authMap.policy,
					tableName: "policy",
				},
				{
					isRender: authMap.suggestion_box,
					tableName: 'feed_back',
				},
				{
					isRender: authMap.operation_log,
					tableName: 'logs',
				},
				{
					isRender: authMap.talent_room,
					tableName: 'flats',
				},

				{
					isRender: true,
					tableName: 'setting',
				},
				{
					isRender: !(authMap.labor_dispatch || authMap.labor_dispatch_accounting || authMap.labor_dispatch_cashier),
					tableName: 'ssbjrcw',
				},

			];

			//
			let renderConfig = processRenderConfig(config);
			let navBarMap = removeInvalidRenderConfig(renderConfig)
			dispatch({ type: 'SET_NAVBAR_CONFIG', data: navBarMap });
			let routes = { ...cftsRoutes };
			routes.children = processRoutes(renderConfig, cftsRoutes.children!);
			let finalRoutes = [...publicRoutes, routes, route_404]
			dispatch({ type: 'SET_PROCESS_ROUTES', data: finalRoutes })
			return { navBarMap, routes: finalRoutes };
		}

	}

	// 处理每层的tableName的拼接
	function processRenderConfig(config: RenderConfigProps[], pName?: string) {
		return config.map((item: RenderConfigProps) => {
			if (pName) item.tableName = `${pName}/${item.tableName}`;
			if (item.children) item.children = processRenderConfig(item.children, item.tableName);
			return item;
		})
	}

	// 依据isRender截取isRender为true的节点
	function removeInvalidRenderConfig(config: RenderConfigProps[]) {
		let renderConfig = config.filter(({ isRender }) => isRender);
		return renderConfig.map((item) => {
			let newItem = { ...item }
			if (item.children) newItem.children = removeInvalidRenderConfig(item.children);
			return newItem
		})
	}

	// 去除无权限的routes
	function processRoutes(config: RenderConfigProps[], routes: routeParams[]) {
		let routeMap = createRenderMap(config);
		return removeMatchedRoute(routes, routeMap)
	}

	/**
	 * 创建路由配置项tableNames节点的集合
	 * @returns [ ['tableName1'], ['tableName2', 'childTableName1'], ...]
	 */
	function createRenderMap(renderConfig: RenderConfigProps[]): Array<string[]> {
		return getTableNameArr(renderConfig).map(c => c.split('/'))
	}

	/**
	 * 获取路由配置项tableNames节点 使用’/‘拼接的字符串
	 * @returns ['tableName1', 'tableName2/childTableName1']
	 */
	function getTableNameArr(items: RenderConfigProps[], arr: string[] = []): string[] {
		items.forEach(c => {
			if (c.children) {
				let childrenArr = getTableNameArr(c.children)
				childrenArr.forEach(tableName => {
					arr.push(tableName)
				})
			}
			if (!c.isRender) arr.push(c.tableName)
		})
		return arr
	}

	/**
	 * 移除匹配的路由
	 * @returns
	 */
	function removeMatchedRoute(routes: routeParams[], tableNameArr: Array<string[]>) {
		return tableNameArr.reduce((routes, tableName) => filterDepthRoutes(routes, tableName), routes);
	}

	/**
	 * 深度遍历路由并返回过滤后的路由
	 * @returns
	 */
	function filterDepthRoutes(routes: routeParams[], keyArr: string[]) {
		if (!keyArr.length) return routes;
		let newRoutes: routeParams[] = [];
		for (let i = 0; i < routes.length; i++) {
			let route = { ...routes[i] }
			if (matchPath(route.path, keyArr[0])) {
				if (keyArr.length > 1) {
					if (route.children) {
						route.children = filterDepthRoutes(route.children, keyArr.slice(1))
					}
				} else continue;
			}
			newRoutes.push(route)
		}
		return newRoutes
	}

	// 对比path过滤后的第一个节点是否匹配
	function matchPath(path: string, key: string) {
		let pathArr = path.split('/').filter(c => (
			![
				!c,
				/:/.test(c),
				c === 'platform'
			].some(c => c)
		));

		return pathArr[0] === key;
	}

	return { routeState, getRouteConfig };
}
